#case-study {
    padding: 6rem 0 5rem;
    overflow-x: hidden;
    position: relative;
    background: url('../images/thumbnail_IMG_3330.jpg') no-repeat;
    background-blend-mode: screen;
    background-position: right;
    background-size: cover;
    &::before {
        top: 0;
        padding: 0;
        display: block;
        content: 'NOCC';
        font-size: 25rem;
        text-align: center;
        width: 100%;
        line-height: 24rem;
        letter-spacing: 2.8rem;
        font-weight: bold;
        color: #23361c;
        opacity: 0.15;
        position: absolute;
        z-index: 3;
    }
}
.case-study {
    &__title {
        position: relative;
        z-index: 3;
        color: #ff9100;
        font-weight: bold;
        text-align: center;
        font-size: 3rem;
    }
    &__img-wrapper {
        flex: 1;
        width: 45%;
    }
    &__img {
        position: relative;
        width: auto;
        height: 100%;
        text-align: center;
    }
    &__wrapper {
        display: flex;
        width: 100%;
        margin-top: 4rem;
        align-items: center;
    }
}
.hero__screen {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    background-color: rgba(215, 228, 210, 0.849);
}
.section {
    &__hero {
        position: relative;
        
    }
    &__image {
        margin-top: 4rem;
        display: inline-block;
        position: relative;
        z-index: 1;
        width: 50%;
    }
}

.nocc {
    min-height: 300px;
    display: flex;
    margin: 6rem 0 4rem;
    &__video {
        width: 50%;
        position: relative;
        z-index: 1;
    }
    &__quote {
        font-size: 1.25rem;
        width: 50%;
        padding: 0 2.5rem 0;
        position: relative;
        z-index: 1;
        color: $dark-green;
    }
    &__extra-video {
        text-align: center;
    }
}
.extra-video__link {
    color: #209965;
    text-decoration: none;
}

.numbers {
    &-highlight {
        background-color: #63B54E;
        position: relative;
        z-index: 3;
        padding: 2rem;
    }
    &__title {
        font-size: 8rem;
        color: #e8ffe1;
    }
    &__text {
        padding-left: 1rem;
        position: relative;
        top: -30px;
        font-size: 2rem;
        color: #f9ea80;
    }
}
.quote__ref {
    padding-top: 1rem;
    text-align: right;
}
.app__links {
    margin: 0 auto;
    width: 300px;
}

.links {
    &__text {
        position: relative;
        z-index: 1;
        text-align: center;
        padding: 1rem;
        font-size: 1.25rem;
        color: #3d6755;
        font-weight: bold;
        &--orange {
            color: #EA8034;
        }
    }
}

.app-store {
    position: relative;
    z-index: 1;
    display: flex;
    justify-content: space-between;
}

.store {
    &__link {
        width: 45%;
    }
}

@media screen and (max-width: 1200px) {
    .brochure {
        width: 350px;
    }
}

@media screen and (max-width: 900px) {
    .nocc {
        // &__video {
        // }
        &__quote {
            padding: 0 1rem;
        }
    }
}

@media screen and (max-width: 760px) {
    #case-study {
        &::before {
            font-size: 15rem;
        }
    }
    .brochure {
        width: 250px;
    }
    .case-study {
        &__wrapper {
            flex-direction: column;
        }
        &__img-wrapper {
            width: 70%;
            text-align:center;
            padding: 2rem 0;
        }
    }
    .nocc {
        flex-direction: column;
        &__video {
            width: 65%;
            height: 300px;
            margin: 0 auto;
        }
        &__quote {
            margin: 2.5rem auto 0;
            width: 75%;
            text-align: center;
        }
    }
}

@media screen and (max-width: 700px) {
    .nocc {
        flex-direction: column;
        align-items: center;
        &__video {
            width: 60%;
            height: 300px;
        }
        &__quote {
            margin-top: 2rem;
            width: 60%;
            padding: 2rem 0;
        }
    }
    .brochure {

        width: 250px;
        right: 10px;
        bottom: -50px;
        transform: rotate(0deg);
    }
}
@media screen and (max-width: 600px) {
    #case-study {
        &::before {
            font-size: 10rem;
            line-height: 15rem;
        }
    }
    .nocc {
        flex-direction: column;
        align-items: center;
        &__video {
            width: 80%;
            height: 300px;
        }
        &__quote {
            width: 80%;
            padding: 2rem 0;
        }
    }
    .numbers {
        &__title {
            text-align: center;
        }
        &__text {
            text-align: center;
        }
    }
    .app__links {
        padding-top: 3rem;
        margin: 0 auto;
    }
    .brochure {
        display: none;
    }
}

@media screen and (max-width: 400px) {
    #case-study {
        &::before {
            font-size: 8rem;
            line-height: 10rem;
        }
    }
    .case-study__img-wrapper {
        width: 100%;
    }
    .nocc {
        flex-direction: column;
        align-items: center;
        &__video {
            width: 100%;
            height: 300px;
        }
        &__quote {
            width: 100%;
            padding: 2rem 0;
        }
    }
}