.page-footer {
    padding: 2rem 0 3rem;
}

.social-media{
    &__icons {
        font-size: 2rem;
        display: flex;
        justify-content: center;
        list-style: none;
        margin: 1rem 0;
        cursor: pointer;
    }
    &__link {
        text-decoration: none;
        color: $orange;
        &:hover {
            color: $light-orange;
        }
    }
    &__icon {
        margin: 0 1rem;
    }
}

.footer{
    &__info {
        display: flex;
        justify-content: center;
        list-style: none;
    }
    &__entry {
        margin: 0 4rem;
    }
    &__link {
        text-decoration: none;
        color: $orange;
        &:hover {
            color: $light-orange;
        }
    }
    
}

@media screen and (max-width: 575px) {
    .footer {
        &__entry {
            font-size: 0.8rem;
            margin: 0 1rem;
        }
    }
}
