* {box-sizing:border-box}


.mobile-phone {
    position: relative;
    margin:1rem auto;
    width: 300px;
    height: 490px;
    &--light {
      background: url('../../src/images/mobile_phone_light.png');
      background-size: cover;
      background-repeat: no-repeat;
      background-position: center;
    }
    &--dark {
      background: url('../../src/images/mobile_phone_dark.png');
      background-size: cover;
      background-repeat: no-repeat;
      background-position: center;
    }
}
/* Slideshow container */
.slideshow-container {  
    width: 212px;
    height: auto;
    position: absolute;
    margin: auto;
}

/* Hide the images by default */
.mySlides {
  display: none;
}

.slideshow__img {
  width: auto;
  height: 100%;
}
/* Next & previous buttons */
.slide-btn.prev, .slide-btn.next {
  cursor: pointer;
  position: absolute;
  top: 35%; 
  width: auto;
  padding: 16px;
  color: white;
  font-weight: bold;
  font-size: 36px;
  transition: 0.6s ease;
  border-radius: 0 3px 3px 0;
  user-select: none;
}
.slide-btn.prev {
  left: -60px;
}
/* Position the "next button" to the right */
.slide-btn.next {
  right: -60px;
  border-radius: 3px 0 0 3px;
}

/* On hover, add a black background color with a little bit see-through */
.prev:hover, .next:hover {
  background-color: rgba(0,0,0,0.8);
}

/* Caption text */
.text {
  color: #f2f2f2;
  font-size: 15px;
  padding: 8px 12px;
  position: absolute;
  bottom: 8px;
  width: 100%;
  text-align: center;
}

/*  Image Light and Dark mode */ 
.show {
  display: block;
}
.hide {
  display: none;
}


/* Fading animation */
.fade {
  -webkit-animation-name: fade;
  -webkit-animation-duration: 1.5s;
  animation-name: fade;
  animation-duration: 1.5s;
}

@-webkit-keyframes fade {
  from {opacity: .4}
  to {opacity: 1}
}

@keyframes fade {
  from {opacity: .4}
  to {opacity: 1}
}
@media screen and (max-width: 700px) {
  .mobile-phone {
    margin: 0 auto;
    width: 200px;
    height: 327px;
  }
  .slideshow-container {
    top: 38px;
    width: 140px;
  }
  .industry-info {
    padding-bottom: 2rem;
  }
  .slide-btn.next {
    right: -140px;
  }
}

