.modal {
    &__wrapper {
        position: fixed;
        background-color: rgba(0, 0, 0, 0.644);
        top: 0;
        left: 0;
        z-index: 100;
        height: 100%;
        width: 100%;
        opacity: 1;
        transition: all 0.5s linear;
        &--hide {
            display: none;
            opacity: 0;
        }
    }
    &__body {
        top: -100%;
        left: 50%;
        position: absolute;
        overflow-y: scroll;
        background-color: $light-yellow;
        width: 70%;
        max-height: 80%;
        padding: 2rem;
        transform: translateX(-50%);
        transition: all 0.5s linear;
        &--show {
            top: 5%;
        }
    }
    &__title {
        margin-bottom: 1.5rem;
    }
    &__copy {
        margin-bottom: 1.5rem;
    } &__subheading {
        margin: 1.5rem 0 1rem;
    }
}

@media screen and (max-width: 700px) {
    .modal {
        &__body {
            width: 90%;
        }
    }
}