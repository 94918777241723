#login-portal {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background-color: #222;
    &:after {
        content: '';
        position: absolute;
        height: 100%;
        width: 80%;
        background: url('../../src/images/PointNSaveLOGO.png');
        background-position: 50% 95%;
        background-size: contain;
        background-repeat: no-repeat;
        opacity: 0.1;
     }
}

img {
    width: 100%;
    height: auto;
}

.portal {
    position: relative;
    z-index: 2;
    display: flex;
    flex-direction: column;
    width: 40%;
    margin-top: -14rem;
    background-color:white;
    min-height: 200px;
    padding: 1.5rem 1rem;
    border-radius: 10px;
    &__form {
        width: 90%;
        text-align: center;
        margin: 0 auto;
    }
    &__title {
        font-size: 2rem;
        margin-bottom: 1.25rem;
    }
    &__text {
        margin-top: 1rem;
        text-align: center;
        font-size: 1.25rem;
        color: #777;
    }
    &__link {
        text-decoration: none;
        cursor: pointer;
        font-weight: bold;
        color: $orange;
    }
}

.portal select {
    padding: 10px;
    border-radius: 2px;
    width: 80%;
    font-size: 1.25rem;
}

.portal select, .portal .btn {
    margin: 0.5rem 0;
}

@media screen and (max-width: 1000px) {
    .portal {
        width: 70%;
        margin-top: -4rem;
    }
    #login-portal {
        &:after {
            width: 100%;
        }
    }
}

@media screen and (max-width: 900px) {
    .portal {
        width : 80%;
    }
    #login-portal {
        background-position: 50% 10%;
    }
}


@media screen and (max-width: 600px) {
    .portal {
        width : 90%;
    }
    .portal select {
        width: 95%;
    }
    .portal select option {
        font-size: 1rem;
        width: 60%;
    }
}

@media screen and (max-width: 515px) {
    .portal__form option{
        width : 100px;
    }
    .portal {
        &__title {
            font-size: 1.5rem;
        }
        &__text {
            font-size: 1rem;
        }
    }
}

@media screen and (max-width: 400px) {
    .portal {
        margin-top: 0rem;
    }
}
