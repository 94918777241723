#dashboard {
    position: relative;
    padding: 5rem 0;
    background: url('../images/laptop-screen.jpg') no-repeat center;
    background-size: cover;
}

.dashboard {
    &__multiply-layer {
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        background-color:  rgba(0,0,0,0.7);
        
    }
    &__video {
        width: 50%;
        height: 400px;
        margin: 5rem auto;
    }
    &>iframe {
        min-height: 500px;
    }
}

#dashboard-selection {
    padding: 5px;
    font-size: 1.5rem;
}

@media screen and (max-width: 900px) {
    .dashboard {
        &__video {
            width: 70%;
            min-height: 400px;
        }
    }
}

@media screen and (max-width: 700px) {
    .dashboard {
        &__video {
            width: 80%;
            max-height: 300px;
        }
    }
}

@media screen and (max-width: 600px) {
    .dashboard {
        &__video {
            width: 95%;
            
        }
    }
    #dashboard-selection {
        font-size: 1.25rem;
    }
}
