/*  COLOR CHOICES */

$dark-blue: #094050;
$darker-blue: darken($dark-blue, 5%);
$light-gray: #cccccc;
$green: #045C59;
$dark-green: #142D29;
$light-green: #BCDD97;
$lighter-green: lighten($light-green, 40%);
$yellow: #d9a33f;
$cream: #FFF4C5;
$dark-yellow: darken($yellow, 20%);
$darker-yellow: #b17300;
$light-yellow: lighten($yellow, 10%);
$red: #b71f2a;
$orange: #C35146;
$dark-orange: #742018;
$nocc-orange: #FF9200;
$nocc-green: #63B54E;
$light-orange: #FF973E;