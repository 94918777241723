#hero {
    position: relative;
    
    padding-bottom: 2.5rem;
}
.hero-quote {
    line-height: 1.6rem;
}
.quote-author {
    margin-top: 1rem;
    text-align: right;
}
.carousel__images {
    position: absolute;
    top: 0;
    height: 100%;
    width: 100%;
    transform: translateX(0%);
    overflow: hidden;
}

.carousel__controls {
    position: relative;
}
.carousel__control {
    position: absolute;
    top: 250px;
    font-size: 3rem;
    cursor: pointer;
    z-index: 5;
    color: #e0e0e0;
    transition: all 0.5s;
}

.carousel__control:hover {
    color: #fff;
}

.carousel__control--left-control {
    left: 40px;
}

.carousel__control--right-control {
    right: 40px;
}
.carousel__slider {
    position: absolute;
    top: 500px;
    left: 50%;
}
.carousel__slide {
    width: 20px;
    height: 20px;
    border-radius: 10px;
    border: 3px solid #fff;
}

.carousel__panels {
    width: 100%;
    height: 100%;
    display: flex;
    transform: translateX(-100%)
}
.carousel__panel 
{   
    width: 100%;
    height: 100%;
    flex-shrink: 0;
    &--1 {
        background: url('../images/Gladwin_County.jpg') no-repeat center;
        background-size: cover;
    }
    &--2 {
        background: url('../images/chino_valley_2.jpg') no-repeat center;
        background-size: cover;
    }
    &--3 {
        background: url('../images/Silsbee.jpg') no-repeat center;
        background-size: cover;
    }
    &--4 {
        background: url('../images/city_of_Covina.jpg') no-repeat center;
        background-size: cover;
    }
}
.container--hero {
    display: flex;
    flex-direction: column;
    
}
#quote-author {
    text-align: right;
}
.hero {
    &__wrapper {
        position: relative;
        padding-top: 15%;
        text-align: left;
        z-index: 1;
        color: white;
    }
    &__multiply-layer {
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        background: linear-gradient(180deg, rgba(255,255,255,0) 15%, rgba(0,0,0,1) 100%);
    }
    &__quote {
        color: white;
        width: 50%;
        min-height: 200px;
    }
        
    &__wrapper--btn {
        width: 100%;
        display: flex;
        margin-top: 10rem;
        justify-content: space-between;
        align-items: center;
        z-index: 2;
    }
    &__title {
        font-size: 3.5rem;
        line-height: 4rem;
        font-family: 'Ubuntu', sans-serif;
        letter-spacing: 3px;
    }
    &__subtitle {
        font-size: 2rem;
        font-weight: 500;
    }
}


@media screen and (max-width: 1278px) {
    .hero {
        &__title {
            font-size: 3rem;
        }
        &__wrapper--btn {
            // padding: 4rem 0 0 64%;
        }
    }
}

@media screen and (max-width: 930px) {
    .hero {
        &__wrapper--btn {
            // padding: 2rem 0 0 55%;
            flex-direction: column;
            justify-content: center;
            margin-top: 5rem;
        }
        &__quote {
            width: 80%;
            margin-bottom: 1rem;
        }
    }
}


@media screen and (max-width: 768px) {
    .hero {
        &__wrapper {
            text-align: center;
            width: 100%;            
        }
        &__title {
            margin-top: 4rem;
        }
        &__quote {
            width: 90%;
        }
    }     
}

@media screen and (max-width: 525px) {
    .hero {
        &__wrapper {
            text-align: center;
            width: 100%;
        }
        &__title {
            font-size: 2rem;
            line-height: 2.5rem;
        }
        &__subtitle {
            font-size: 1.75rem;
        }
        &__wrapper--btn {
            justify-content: center;
        }
        &__multiply-layer {
            background: linear-gradient(180deg, rgba(255,255,255,0) 15%, rgba(0,0,0,1) 80%);
        }
    } 
    .carousel__panel {
 
        &--1 {
            background: url('../images/Gladwin_County_mobile.jpg') no-repeat center;
            background-size: cover;
        }
        &--2 {
            background: url('../images/chino_valley_2_mobile.jpg') no-repeat center;
            background-size: cover;
        } 
    }
}
