#contact-form {
    position: relative;
    z-index: 1;
    background-color: $orange;
    padding: 5rem 0 ;
    color: white;
}

.contact__body {
    display: flex;
    flex-wrap: wrap;
}

.form {
    &-control {
        width: 50%;
        padding:0 2rem 2rem 0;
    }
    &-field {
        margin: 1rem 0;
        &:first-child {
            margin-top: 0;
        }
    }
    &__input-field {
        width: 100%;
        padding: 1rem;
        font-size: 1.25rem;
    }
    &__input-selector {
        width: 100%;
        padding: .5rem 1rem;
        font-size: 1.25rem;
    }
    &__textarea {
        width: 100%;
        padding: .5rem 1rem;
        font-size: 1.5rem;
    }
}

option {
    font-size: 1.25rem;
    padding: .5rem;
}

.contact-form {
    &__image {
        width: 50%;
        display: flex;
        flex-direction: column;
    }
    &__img {
        display: block;
    }
}
.contact-form__quote footer {
    padding: 1rem 0;
    font-style: normal;
    text-align: right;
}

@media screen and (max-width: 850px) {
    .form {
        &-control {
            width: 100%;
            padding: 0;
            margin-bottom: 2rem;
        }
    }
    .contact-form__image {
        width: 80%;
        margin: 0 auto 3rem;
    }
}

